import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';

import { AppProvider } from './context/AppContext';

import Dashboard from './views/Dashboard';
import OrganizationInfo from './views/OrganizationInfo';
import LoginPage from './views/LoginPage';
import Orders from './views/Orders';
import FillingDegree from './views/FillingDegree';
import NotAuthorized from './views/NotAuthorized';
import NotImplemented from './views/NotImplemented';
import OpsDashboard from './views/OpsDashboard';
import PicksDashboard from './views/PicksDashboard';
import OrgsDashboard from './views/OrgsDashboard';
import Scoreboard from './views/Scoreboard';
import CreateOrganization from './views/CreateOrganization';
import Fonts from './components/Fonts';
import theme from './theme';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <AuthenticatedTemplate>
      <Router>
        <AppProvider>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/organization" element={<OrganizationInfo />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/filling" element={<FillingDegree />} />
            <Route
              path="/create-organization"
              element={<CreateOrganization />}
            />
            <Route path="/ops" element={<OpsDashboard />} />
            <Route path="/picks" element={<PicksDashboard />} />
            <Route path="/orgs" element={<OrgsDashboard />} />
            <Route path="/scoreboard" element={<Scoreboard />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/not-implemented" element={<NotImplemented />} />
          </Routes>
        </AppProvider>
      </Router>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Router>
        <Routes>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Router>
    </UnauthenticatedTemplate>
  </ChakraProvider>
);
