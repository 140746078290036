import { Box, Container, Text, Center, Icon, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaRobot } from 'react-icons/fa';

export const FancyLoadingScreen = () => {
  return (
    <Container maxW={'container.xl'} maxH={'container.xl'}>
      <Center mt={200}>
        <VStack>
          <Box>
            <motion.div
              animate={{
                rotate: [0, 360]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'loop',
                ease: 'linear'
              }}
            >
              <Icon as={FaRobot} w={20} h={20} />
            </motion.div>
          </Box>
          <Text fontSize={'xl'}>Loading...</Text>
        </VStack>
      </Center>
    </Container>
  );
};
